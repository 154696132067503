export interface NewsGetDto {
  id: number;
  title: string;
  content: string;
  // author: string;
  image: string;
  published: boolean;
  sliderId: number;
  slug: null;
  type: number;
  updatedAt: Date;
  slider: Slider;
  link?: string;
  createdAt: Date;
}

export interface Slider {
  id: number;
  newsId: number;
  images: Image[] | any;
}

export interface Image {
  id: number;
  image: string;
  order: number;
  sliderId: number;
}

export interface NewsGetObject {
  limit: number;
  news: NewsGetDto[] | any;
  lastId: number;
  type: number;
  noData: boolean;
}

export enum NewsType {
  TELAVI = 1,
  VARIOUS = 2,
  REALESTATE
}
